import React from "react";
import "../sass/client.scss";
import { createInertiaApp } from "@inertiajs/react";
import { createRoot } from "react-dom/client";

if (document.getElementById("app")) {
    createInertiaApp({
        progress: {
            color: '#EA1266',
          },
        resolve: (name) => {
            const pages = import.meta.glob("./Pages/**/*.jsx", { eager: false });
            let page = pages[`./Pages/${name}.jsx`];
            // page.default.layout ??= (page) => (
            //     <>
            //         <Head title="Meta Mata" />
            //         <Layout children={page} />
            //     </>
            // );

            return page();
        },
        setup({ el, App, props }) {
            createRoot(el).render(<App {...props} />);
        },
    });
}
